<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-center" v-if="result && !result.analysis && !result.unavailable">
      <v-col>
        <div class="mt-5 text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p class="body font-weight-thin">Chargement...</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="result.unavailable" class="align-center">
      <v-col class="fill-height">
        <v-scroll-y-transition mode="out-in">
          <div class="text-center">
            <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg" max-width="200"></v-img>
            <p class="display-1 font-weight-thin grey--text text--lighten-1">
              Aucune transcription n'a été chargée
            </p>
            <v-btn small class="mt-4" color="teal" outlined :to="url('upload/text')">
              <v-icon small>mdi-upload</v-icon> Uploader un fichier
            </v-btn>
          </div>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-tabs background-color="white" v-model="tabs" centered light grow>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-raw">
          <span><v-icon>mdi-chart-bar</v-icon>Statistiques</span>
        </v-tab>

        <v-tab href="#tab-trend">
          <span><v-icon disabled>mdi-chart-line</v-icon> Évolutions par tour de parole</span>
        </v-tab>

        <v-tab href="#tab-postag">
          <span><v-icon>mdi-chart-bar</v-icon> Étiquetage morpho-syntaxique</span>
        </v-tab>

        <v-tabs-items v-model="tabs">
          <v-tab-item value="tab-raw">
            <v-simple-table v-if="result.analysis" height="350px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Données</th>
                    <th v-for="(item,i) in result.analysis"
                    :key="i">Participant: {{ item.participant}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Tour</td>
                    <td v-for="(item, i) in result.analysis" :key="i">
                      {{ item.turns }}
                    </td>
                  </tr>
                  <tr>
                    <td>Nombre de mots</td>
                    <td v-for="(item, i) in result.analysis" :key="i">
                      {{ item.words }}
                    </td>
                  </tr>
                  <tr>
                    <td>Longueur moyenne mots</td>
                    <td v-for="(item, i) in result.analysis" :key="i">
                      {{ item.wordsLength }}
                    </td>
                  </tr><tr>
                    <td>Nombre mots moyen / TDP</td>
                    <td v-for="(item, i) in result.analysis" :key="i">
                      {{ item.wordsPerTurn }}
                    </td>
                  </tr><tr>
                    <td>Ratio stopwords</td>
                    <td v-for="(item, i) in result.analysis" :key="i">
                      {{ item.stopwordRatio }}
                    </td>
                  </tr><tr>
                    <td>Tour par minute</td>
                    <td v-for="(item, i) in result.analysis" :key="i">
                      {{ item.turnsPerMinut | round }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="subtitle font-weight-thin mt-5">Données globales</p>
            <v-row v-if="result.general">
              <v-col>
              <LabelledNumber label="Nombre mot moyen par tour de parole"
              :value="result.general.average"
              color="black"/>
              </v-col><v-col>
              <LabelledNumber label="Durée totale (min)"
              :value="result.general.duration"
              color="black"/>
              </v-col><v-col>
              <LabelledNumber label="Tour par minute"
              :value="result.general.turnsPerMinut"
              color="black"/>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-trend">
              <v-row class="d-flex justify-space-around">
                <v-col v-for="(item, i) in result.analysis" :key="i">
                  <v-card elevation="0" >
                    <v-card-title>Participant: {{ item.participant }}</v-card-title>
                    <p class="overline text-center">Nombre de mots</p>
                    <v-sparkline
                    :value="item.numberOfWordsHistogram"
                    :smooth="2" fill type="trend" :color="colors[i]" auto-line-width
                    auto-draw></v-sparkline>
                    <p class="overline text-center">Pauses</p>
                    <v-sparkline
                    :value="item.pausesHistogram"
                    :smooth="2" fill type="trend" :color="colors[i]" auto-line-width
                    auto-draw></v-sparkline>

                    <p class="overline text-center">Longueur moyenne des mots</p>
                    <v-sparkline
                    :value="item.wordsAverageLengthHistogram"
                    :smooth="2" fill type="trend" :color="colors[i]" auto-line-width
                    auto-draw></v-sparkline>
                    <p class="overline text-center">Lemmes</p>
                    <v-sparkline
                    :value="item.lemmasCumulativeHistogram"
                    :smooth="2" fill type="trend" :color="colors[i]" auto-line-width
                    auto-draw></v-sparkline>
                  </v-card>
                </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-postag">
            <v-row class="d-flex justify-space-around">
              <v-col v-for="(item, i) in result.analysis" :key="i">
                <v-card elevation="0" >
                  <v-card-title>Participant: {{ item.participant }}</v-card-title>

                  <div v-for="item in item.mostUsedTags" :key="item.tag">
                    <v-progress-linear class="mt-2"
                    :value="item.ratio*100" height="25" :color="colors[i]" striped reactive>
                    {{ item.tag }}: {{ item.ratio*100 | round }}%
                  </v-progress-linear>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-row>
</v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import LabelledNumber from '@/components/common/LabelledNumber.vue';

export default {
  name: 'InterviewToolBasics',
  mixins: [MixinPatients, MixinInterviews],
  components: { LabelledNumber },
  props: {
    patientId: String,
    interviewId: String,
  },
  data: () => ({
    tabs: null,
    result: Object(),
  }),
  async created() {
    this.result = await this.getAnalysisResult(this.patientId, this.interviewId, ['raw']);
  },
};
</script>
